@tailwind base;
@tailwind components;
@tailwind utilities;

.footerBar {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 85px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarNav {
  width: 100%;
  margin-top: 0.75rem;
  overflow-y: hidden;
}

.navbarListItems {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navbarListItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbarListItemName,
.navbarListItemNameActive {
  margin-top: 0.25rem;
  font-size: 14px;
  font-weight: 600;
  color: #8f8f8f;
}
.navbarListItemNameActive {
  color: #2c2c2c;
}

.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

.signin-pass-wrapper > i {
  position: absolute;
  top: 60%;
  right: 10%;
}

.passwordInputDiv {
  position: relative;
}

.showPassword {
  cursor: pointer;
  position: absolute;

  top: 40%;
  right: 1%;
  padding: 1rem;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #570df8 transparent #570df8 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.socialLogin {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialIconDiv {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  margin: 1rem;
  width: 3rem;
  height: 3rem;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.socialIconImg {
  width: 100%;
}
